import React from "react";

const PageError = React.lazy(() => import("./pages/Pages/Special/PageError"));
const Begin = React.lazy(() => import("./pages/Begin"));
// const Begin_b = React.lazy(() => import("./pages/Begin_b"));

const Privacy = React.lazy(() => import("./pages/Agreement/Privacy"));
const Terms = React.lazy(() => import("./pages/Agreement/TermsOfService"));
const About = React.lazy(() => import("./pages/About"));
const Fee = React.lazy(() => import("./pages/Fee"));
const Diagnosis = React.lazy(() => import("./pages/Diagnosis"));
const ServicePA = React.lazy(() => import("./pages/ServicePA"));
const ServiceResearch = React.lazy(() => import("./pages/ServiceResearch"));
const ServicePAPlus = React.lazy(() => import("./pages/ServicePAPlus"));

const UsageAgreement = React.lazy(() => import("./pages/Begin/UsageAgreement"));
const Result = React.lazy(() => import("./pages/Begin/Result"));
const Application = React.lazy(() => import("./pages/Application"));
const BeginToApplication = React.lazy(() =>
	import("./pages/Application/Begin")
);

const Dashboard = React.lazy(() => import("./pages/Dashboard"));
const DemoLogin = React.lazy(() => import("./pages/Demo/Login"));
const Demo = React.lazy(() => import("./pages/Demo"));

const Signup = React.lazy(() => import("./pages/Auth/Signup"));
const Login = React.lazy(() => import("./pages/Auth/Login"));
// const Settings = React.lazy(() => import("./pages/Auth/Settings"));
const ForgotPassword = React.lazy(() => import("./pages/Auth/ForgotPassword"));

const HelpCenterFaqs = React.lazy(() => import("./pages/FAQ/HelpCenterFaqs"));
const routes = [
	//Contct without layout
	{ path: "/page-error", component: PageError, isWithoutLayout: true },

	// Landings
	{ path: "/", component: Begin, exact: true },
	{ path: "/index", component: Begin, exact: true },
	{ path: "/oauth", component: Login, exact: true, isWithoutLayout: true },

	// { path: "/b", component: Begin_b, exact: true },

	{ path: "/faq", component: HelpCenterFaqs, exact: true },
	{ path: "/privacy", component: Privacy, exact: true },
	{ path: "/usage-agreement", component: UsageAgreement, exact: true },
	{ path: "/terms", component: Terms, exact: true },

	{ path: "/about", component: About, exact: true },
	{ path: "/fee", component: Fee, exact: true },
	{ path: "/diagnosis", component: Diagnosis, exact: true },
	{ path: "/service-pa", component: ServicePA, exact: true },
	{ path: "/service-pa-plus", component: ServicePAPlus, exact: true },
	{ path: "/service-research", component: ServiceResearch, exact: true },

	{ path: "/result", isWithoutLayout: true, component: Result, exact: true },
	{
		path: "/application",
		isWithoutLayout: false,
		component: Application,
		exact: true,
	},
	{
		path: "/begin",
		isWithoutLayout: true,
		component: BeginToApplication,
		exact: true,
	},
	{
		path: "/signup",
		isWithoutLayout: true,
		component: Signup,
		exact: true,
	},
	{
		path: "/login",
		isWithoutLayout: true,
		component: Login,
		exact: true,
	},
	{
		path: "/forgotpassword",
		isWithoutLayout: true,
		component: ForgotPassword,
		exact: true,
	},
	{
		path: "/dashboard",
		component: Dashboard,
		isWithoutLayout: false,
		exact: true,
	},
	{
		path: "/demologin",
		component: DemoLogin,
		isWithoutLayout: true,
		exact: true,
	},
	{
		path: "/demo",
		component: Demo,
		isWithoutLayout: true,
		exact: true,
	},

	//   { path: "/", component: Root, isWithoutLayout: true, exact: true },
	{ component: PageError, isWithoutLayout: true, exact: false },
];

export default routes;
