import { CognitoUserPool } from "amazon-cognito-identity-js";

const poolData = {
	UserPoolId: process.env.REACT_APP_userPoolId,
	ClientId: process.env.REACT_APP_userPoolWebClientId,
};

// UserPoolId: "ap-northeast-2_T9Oyq1BPj",
// ClientId: "5kddnp2ufhlvhfikdcojett3o4",

export default new CognitoUserPool(poolData);
