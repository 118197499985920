import React, { createContext } from "react";
import { CognitoUser, AuthenticationDetails } from "amazon-cognito-identity-js";
import Pool from "../../UserPool";
import { useHistory } from "react-router-dom";

const AccountContext = createContext();

function Account(props) {
	const history = useHistory();

	const getSession = async () => {
		return await new Promise((resolve, reject) => {
			const user = Pool.getCurrentUser();
			if (user) {
				user.getSession(async (err, session) => {
					if (err) {
						reject();
					} else {
						const attributes = await new Promise((resolve, reject) => {
							user.getUserAttributes((err, attributes) => {
								if (err) {
									reject(err);
								} else {
									const results = {};
									for (let attribute of attributes) {
										const { Name, Value } = attribute;
										results[Name] = Value;
									}

									resolve(results);
								}
							});
						});

						const token = session.getIdToken().getJwtToken();

						resolve({
							user,
							headers: {
								Authorization: token,
							},
							...session,
							...attributes,
						});
					}
				});
			} else {
				reject();
			}
		});
	};

	const authenticate = async (Username, Password) => {
		return await new Promise((resolve, reject) => {
			const user = new CognitoUser({
				Username,
				Pool,
			});

			const authDetails = new AuthenticationDetails({
				Username,
				Password,
			});

			user.authenticateUser(authDetails, {
				onSuccess: (data) => {
					resolve(data);
				},
				onFailure: (err) => {
					reject(err);
				},
				newPasswordRequired: (data) => {
					resolve(data);
				},
			});
		});
	};

	const logout = () => {
		const user = Pool.getCurrentUser();
		if (user) {
			user.signOut();
			history.push("/index");
		}
	};

	return (
		<AccountContext.Provider value={{ authenticate, getSession, logout }}>
			{props.children}
		</AccountContext.Provider>
	);
}

export { Account, AccountContext };
